<template>
  <transition name="fade">
    <div class="recipe-view-item">
      <router-link
        :to="recipe.attributes.path.alias"
        @click.native="selectRecipe(recipe)">
        <div
          v-if="typeof recipe.attributes.field_cooking_time !== 'undefined'"
          class="cooking-time">
          <font-awesome-icon
            class="icon"
            icon="clock"/>
          {{ recipe.attributes.field_cooking_time }}
        </div>
        <img :src="image" />
        <h2>{{ recipe.attributes.title }}</h2>
      </router-link>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'RecipesViewItem',

  props: {
    recipe: {
      type    : Object,
      required: true,
    },
  },

  data () {
    return {
      image: null,
    };
  },

  created () {
    this.image = this.$store.getters.getRecipeImageUrl(this.recipe, 'search_thumbnail');
  },

  methods: {
    ...mapActions([
      'selectRecipe',
    ]),

    ...mapGetters([
      'getRecipeImageObject',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.recipe-view-item {
    transition: all .5s;

}
.fade-enter, .fade-leave-to
    /* .card-leave-active for <2.1.8 */ {
    opacity: 0;
    transform: scale(0);

}
.fade-enter-to {
    opacity: 1;
    transform: scale(1);
    transition-delay: .5s;
}

a {
    color: #2c3e50;
    text-decoration: none;
}

.recipe-view-item {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    margin: 0 0 20px 0;
    position: relative;
    text-align: left;
    width: 356px;

    a {
        display: block;
    }

    h2 {
        font-weight: normal;
        margin: 0;
        margin: 10px 30px;
    }

    img {
        border-bottom: 2px solid $primary-color;
        max-width: 100%;
    }

    .cooking-time {
        align-items: center;
        background-color: #ffffff;
        display: flex;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 50px;

        .icon {
            margin-right: 10px;
        }
    }
}
</style>
