<template>
  <div class="container">
    <transition name="router-transition">
      <div
        v-if="$router.currentRoute.name === 'Recipes'"
        class="recipes-view">
        <div class="header">
          <h1>Recepten</h1>
          <div class="toolbox">
            <div class="filter-container aside">
              <recipe-filter/>
            </div>
            <div class="aside"/>
          </div>
        </div>
        <keep-alive>
          <div class="recipes-view-items">
            <RecipesViewItem
              v-for="recipe in recipesArray"
              v-show="recipe.showInOverview === true"
              :key="recipe.id"
              :recipe="recipe"/>
          </div>
        </keep-alive>
      </div>
    </transition>

    <transition name="router-transition">
      <router-view class="window"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RecipeFilter from '@/components/RecipeFilter';
import RecipesViewItem from '@/components/RecipesViewItem';

export default {
  name: 'RecipesView',

  components: { RecipeFilter, RecipesViewItem },

  metaInfo () {
    return {
      title: 'Recepten',
      meta : [
        {
          name   : 'description',
          content: 'De lekkerste recepten vind je op Koken en Recepten! Gezond, gemakkelijk te koken en voor elk wat wils.',
        },
      ],
    };
  },

  data () {
    return {};
  },

  computed: {
    ...mapGetters([
      'recipesArray',
    ]),
  },

  created () {
    this.searchRecipes();
  },

  methods: {
    ...mapActions([
      'searchRecipes',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.router-transition-enter-active, .router-transition-leave-active {
    transition: all 1s ease;
}

.router-transition-leave-active {
    position: absolute;
}

.router-transition-enter, .router-transition-leave-to {
    /*transform: translateX(100%);*/
    opacity: 0;
}

.container {
    margin: 70px auto 0;
    max-width: 1112px;
    position: relative;
}

.recipes-view {
    .header {
        padding: 20px 0;

        h1 {
            margin: 0 0 -27px 0;
        }

        .toolbox {
            > * {
                width: 356px;
            }
        }
    }
}

.recipes-view-items, .toolbox {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: auto;
    justify-items: center;

    @media (min-width: 740px) {
        grid-template-columns: auto auto;
    }

    @media (min-width: 1112px) {
        grid-template-columns: $view-item-width $view-item-width $view-item-width;
        justify-items: left;
    }
}

.window {
    background-color: white;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
}
</style>
