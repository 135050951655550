<template>
  <div
    :class="{'filter-open': filterIsOpen}"
    class="recipe-filter">
    <div
      class="filter-toggle"
      @click="filterToggle()">
      <font-awesome-icon
        class="icon"
        icon="filter"/>
      Filter
    </div>
    <div
      v-if="filterIsOpen"
      class="filter-form">
      <div class="form-item">
        <label for="filter-form-kitchen">Keuken</label>
        <select
          id="filter-form-kitchen"
          v-model="recipeFilters.field_kitchen">
          <option value="">Selecteer een keuken…</option>
          <option
            v-for="(option, index) in kitchenTypes"
            :key="index"
            :value="index">
            {{ option }}
          </option>
        </select>
      </div>

      <div class="form-item">
        <label for="filter-form-meal">Maaltijd</label>
        <select
          id="filter-form-meal"
          v-model="recipeFilters.field_meal">
          <option
            value=""
            selected="selected">Selecteer een maaltijd…</option>
          <option
            v-for="(option, index) in mealTypes"
            :key="index"
            :value="index">
            {{ option }}
          </option>
        </select>
      </div>

      <div class="form-item">
        <label for="filter-form-category">Categorie</label>
        <select
          id="filter-form-category"
          v-model="recipeFilters.field_category">
          <option
            value=""
            selected="selected">Selecteer een categorie…</option>
          <option
            v-for="(option, index) in categoryTypes"
            :key="index"
            :value="index">
            {{ option }}
          </option>
        </select>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'RecipeFilter',

  computed: {
    ...mapGetters([
      'filterIsOpen',
      'recipeFilters',
    ]),

    categoryTypes () {
      return this.$store.getters.getRecipeFilterItems('taxonomy_term--category');
    },
    kitchenTypes () {
      return this.$store.getters.getRecipeFilterItems('taxonomy_term--kitchen');
    },
    mealTypes () {
      return this.$store.getters.getRecipeFilterItems('taxonomy_term--meal');
    },
  },

  watch: {
    filter: {
      handler (newValue) {
        this.setRecipeFilters(newValue);
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations([
      'filterToggle',
      'setRecipeFilters',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables";

.recipe-filter {
    position: relative;
    text-align: left;
    width: 75px;

    .filter-toggle {
        cursor: pointer;
    }

    .filter-form {
        display: grid;
        grid-column-gap: 20px;
        grid-template-columns: auto;
        width: 100%;

        @media (min-width: 740px) {
            grid-template-columns: auto auto auto;
            width: 1140px;
        }

        .form-item {
            margin: 20px 0;

            label {
                display: block;
                margin-right: 10px;
            }

            select {
                background-color: #ffffff;
                padding: 10px;
                width: 280px;
            }
        }
    }

    &.filter-open {
        .filter-toggle {
            color: $primary-color;
        }
    }
}
</style>
